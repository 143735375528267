<route>
{
  "meta": {
    "permission": [
      "Invoices.view_settings"
    ]
  }
}
</route>

<template>
  <v-container fluid>
    <v-card>
      <ValidationObserver v-slot="{ handleSubmit }">
        <form @submit.prevent="handleSubmit(submit)">
          <i-toolbar :title="$tc('invoiceSetting', 1)" :loading="load" dontSave>
            <template class="text-right justify-end" v-slot:submit>
              <v-divider class="mx-4" inset vertical></v-divider>
              <i-btn
                v-if="getPermissions([`Invoices.change_settings`]) || isAdmin"
                text
                :small="$vuetify.breakpoint.smAndDown"
                icon="fa-save"
                :title="$tc('save', 1)"
                color="text-dark"
                :loading="load"
                type="submit"
              >
              </i-btn>
            </template>
          </i-toolbar>
          <v-card-text>
            <v-row>
              <v-col cols="12" sm="6" md="2">
                <v-switch
                  v-model="setting.header"
                  :label="$t('settingTemplate.header')"
                  name="header"
                  color="secondary"
                  outlined
                ></v-switch>
              </v-col>
              <v-col cols="12" sm="6">
                {{ $t('primary_color') }}
                <v-color-picker
                  v-model="setting.primary_color"
                  elevation="1"
                  mode="hexa"
                  canvas-height="120"
                  dot-size="18"
                  outlined
                ></v-color-picker>
              </v-col>
            </v-row>
          </v-card-text>
        </form>
      </ValidationObserver>
    </v-card>
  </v-container>
</template>
<script>
import { mapGetters } from 'vuex'
export default {
  data() {
    return {
      load: false,
      setting: {
        header: true,
        company_info: null,
        primary_color: '#fff'
      }
    }
  },
  computed: {
    ...mapGetters({
      company: 'company/getCompanyData',
      getPermissions: 'session/getPermissions',
      isAdmin: 'session/isAdmin'
    })
  },
  methods: {
    getSetting() {
      this.$api.invoice.setting
        .show({ pk: this.company.pk })
        .then((response) => (this.setting = response.data))
        .catch(() => (this.setting.company_info = this.company.pk))
    },
    submit() {
      this.$api.invoice.setting[
        this.setting.pk !== undefined ? 'edit' : 'create'
      ]({ pk: this.setting.pk, form: this.setting }).then(() => {
        this.$toast.success(
          `${this.$tc('invoiceSetting', 1)} ${this.$tc(
            this.setting.pk !== undefined ? 'edited' : 'created',
            1
          )}`
        )
      })
    }
  },
  mounted() {
    this.getSetting()
  }
}
</script>
